import React from "react";
import "./Loading.scss";

const Loading = ({ CustomLoader }) => {
  return (
    <>
      <div className={CustomLoader ? CustomLoader : "spinner"}>
        <div className="circle one"></div>
        <div className="circle two"></div>
        <div className="circle three"></div>
      </div>
    </>
  );
};

export default Loading;
