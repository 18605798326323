import React from "react";
import CurrentOrganizationProfile from "./CurrentOrganizationProfile";

const OrganizationTabbing = ({
  loader,
  setSize,
  size,
  setName,
  name,
  updateOrgDetails,
}) => {
  const currentOrgProps = {
    loader,
    setSize,
    size,
    setName,
    name,
    updateOrgDetails,
  };

  return (
    <>
      <style>
        {`
        .nav-tabs .nav-link.active {
          background: #7F00FF;
          color: #fff;
        }
    `}
      </style>

      <div>
        {/* <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          mountOnEnter={true}
          // transition={false}
          className={tabbingModule.heading_tabbing}
        >
          <Tab
            eventKey="home"
            title="Profile"
            className={tabbingModule.listing_mpp}
          > */}
        <CurrentOrganizationProfile {...currentOrgProps} />
        {/* </Tab>
        </Tabs> */}
      </div>
    </>
  );
};

export default OrganizationTabbing;
