import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import Header from "../../../../components/Header/Header";
import userModule from "../../../../styles/user.module.scss";
import UseToggle from "../../../../Hooks/UseToggle";
import OrganizationTabbing from "../../../User/UserProfile/CurrentOrganization/OrganizationTabbing";
import { useParams } from "react-router";
import OrganizationConstants from "../../../../Constants/OrganizationConstants/OrganizationConstants";
import { UniversalGetService } from "../../../../Services/_private/UniversalGetService/universalGet.service";
import { UniversalCreateUpdateService } from "../../../../Services/_private/UniversalCreateUpdateService/universalCreateUpdate.service";
import { useRef } from "react";

const OrganizationProfile = () => {
  const { toggle, setToggle } = UseToggle();

  const { uuid } = useParams();
  const { GETPROFILE_API_ENDPOINT } = OrganizationConstants(uuid);
  const { universalCreateUpdate } = UniversalCreateUpdateService;
  const { getProfileData } = UniversalGetService;
  const [loader, setLoader] = useState(true);

  const fetchOrgRef = useRef();
  const [name, setName] = useState("");
  const [size, setSize] = useState("");

  const updateOrgDetails = async () => {
    await universalCreateUpdate(
      { name, size },
      "PATCH",
      `admin/updateOrganisationDetails/${uuid}`
    );
    setLoader(true);
    fetchOrgRef.current();
  };

  const currentOrgProps = {
    loader,
    setSize,
    size,
    setName,
    name,
    updateOrgDetails,
  };

  // FETCHING THE DATA...................

  useEffect(() => {
    const handleFetch = async () => {
      const data = await getProfileData(GETPROFILE_API_ENDPOINT);
      setName(data?.data?.name);
      setSize(data?.data?.size);
      setLoader(false);
    };

    fetchOrgRef.current = handleFetch;

    handleFetch();
  }, [GETPROFILE_API_ENDPOINT, getProfileData]);

  return (
    <>
      <div className={userModule.user_main}>
        <Sidebar sidebar={toggle} UserPage="UserPage" />

        <div className={userModule.page_content}>
          <Header heading={name} changeSidebar={setToggle} />

          <div className={userModule.inner_content}>
            <Container fluid>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <div className={userModule.rank_tab_button}>
                    <OrganizationTabbing {...currentOrgProps} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationProfile;
