import { toast } from "react-toastify";
import config from "../../../config/config.json";
import {
  ApiResponse,
  toastProps,
} from "../../_apiResponses/apiResponse.service";

const { headers, headersWithAuth, handleResponse } = ApiResponse;

// CREATE/UPDATE SERVICE....................

const universalCreateUpdate = async (dataParams, method, endpoint) => {
  try {
    const response = await fetch(
      `${config.API_URL}${endpoint}`,
      headersWithAuth(method, dataParams, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
  } catch (error) {
    toast.error(`${error || "Please try again later..!"}`, toastProps);
  }
};

// API CALL WITH UPLOAD IMAGES......................

const uploadImages = async (userData, endpoint, method) => {
  try {
    let headersData = headers;
    const headersd = {
      authorization: headersData.authorization,
      Accept: "application/json",
    };

    let requestOptions = {
      method: method || "POST",
      headers: headersd,
      body: userData,
      redirect: "follow",
    };
    const response = await fetch(
      `${config.API_URL}${endpoint}`,
      requestOptions
    );
    const data = await response.json();
    handleResponse(data);
  } catch (error) {
    toast.error(`${error || "Please try again later..!"}`, toastProps);
  }
};

export const UniversalCreateUpdateService = {
  universalCreateUpdate,
  uploadImages,
};
