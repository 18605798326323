import React from "react";
import ReactPaginate from "react-paginate";
import "../../styles/pagination.scss";

const Pagination = ({ PageCount, ChangePage }) => (
  <ReactPaginate
    pageCount={PageCount ? PageCount : 1}
    pageRange={2}
    marginPagesDisplayed={2}
    onPageChange={ChangePage}
    containerClassName="pagination"
    previousLinkClassName="page"
    breakClassName="page"
    nextLinkClassName="page"
    pageClassName="pages"
    disabledClassName="disabled"
    activeClassName="active"
  />
);

export default Pagination;
