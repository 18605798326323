/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { toastProps } from "../Services/_apiResponses/apiResponse.service";
import { UniversalCreateUpdateService } from "../Services/_private/UniversalCreateUpdateService/universalCreateUpdate.service";
import { UniversalGetService } from "../Services/_private/UniversalGetService/universalGet.service";
import UseToggle from "./UseToggle";

const UseGetApi = (
  GET_API_ENDPOINT,
  formDetails,
  awardId,
  type, 
  CREATE_UPDATE_API_ENDPOINT,
  rankCategory
) => {
  const { universalGetList } = UniversalGetService;
  const { universalCreateUpdate, uploadImages } = UniversalCreateUpdateService;
  const { toggle, setToggle } = UseToggle(); 
  const { toggle2, setToggle2 } = UseToggle(); 
  const { toggle3, setToggle3 } = UseToggle(); 

  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setcurrentPage] = useState(0);
  const [search, setSearch] = useState("");

  const [formValues, setFormValues] = useState(formDetails);

  const [loader, setLoader] = useState(true);

  const [imageNameState, setImageNameState] = useState("");
  const [imagePreview, setImagePreview] = useState(null);

  // FETCHING THE DATA........................

  const handleFetch = async (skip, GET_API_ENDPOINT, filterCategory) => {
    if(GET_API_ENDPOINT === undefined){
      return false;
    }
    let obj = {};
    obj.search = search;
    obj.skip = skip * 10;
    obj.limit = 10;
    obj.awardId = awardId; 
    obj.type = type;
    obj.filter = filterCategory;
    obj.RankCategory = rankCategory;
    const data = await universalGetList(obj, GET_API_ENDPOINT);
   
    setItems(data?.data);
    setPageCount(Math.ceil(data?.count / 10));
   
    setLoader(false);
  };

  // UPDATE STATUS.............................

  const publishData = async (
    e,
    getUuid,
    getStatus,
    CREATE_UPDATE_API_ENDPOINT,
    GET_API_ENDPOINT,
    POST_API_CALL
  ) => {
    let uuid = e.target.getAttribute(getUuid);
    let status = e.target.getAttribute(getStatus);
    
    let currentStatus = {};
    currentStatus.uuid = uuid;
    currentStatus.status = status;
    setLoader(true);
    let response = await universalCreateUpdate(
      currentStatus,
      POST_API_CALL || "PATCH",
      CREATE_UPDATE_API_ENDPOINT
    );
    
    handleFetch(currentPage, GET_API_ENDPOINT);
    setLoader(false);
  };

  const showImagePreview = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }

    setImageNameState(e.target.files[0].name);
  };

  const getImageDetails = (e) => {
    setFormValues({
      ...formValues,
      getFile: e.target.files[0],
      fileName: e.target.files[0].name,
    });
    showImagePreview(e);
  };

  // SEARCH FUNCTIONALITY................

  const globalSearch = (e) => {
    setSearch(encodeURIComponent(e.target.value));
    setLoader(true);
  };

  // CHANGE THE PAGE.......................

  const handlePageChange = (e) => {
  
    setcurrentPage(e.selected);
    setLoader(true);
  };

  // FILL FORM.............................

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSpecificInputChange = (e) => {
    const { name, value } = e.target;
  
    // Convert value to a number for comparison
    const numValue = Number(value);
  
    const constraints = {
      limit: numValue >= 0,    // Ensure limit is non-negative
      quantity: numValue >= 0   // Ensure quantity is between 0 and 3
    };
  
    if (constraints[name] === false) return; // Exit early if the value is invalid
  
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  
  
  

  // ADD NEW List.............................

  const addnewList = async (CREATE_UPDATE_API_ENDPOINT) => {
    const data = formValues;
    setToggle();
    setLoader(true);
    let response = await universalCreateUpdate(
      data,
      "POST",
      CREATE_UPDATE_API_ENDPOINT
    );
 
    handleFetch(currentPage, GET_API_ENDPOINT);
    setFormValues(formDetails);
  };

  const onFileChange = async (e, endpoint) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    setLoader(true);
    await uploadImages(formData, endpoint);
    handleFetch(currentPage, GET_API_ENDPOINT);
    e.target.value = null;
  };

  const imageManadatory = () =>
    imageNameState === "" &&
    toast.error(`${"Image is Mandatory!"}`, toastProps);

  useEffect(() => {
    handleFetch(currentPage, GET_API_ENDPOINT, "");
  }, [GET_API_ENDPOINT, currentPage, search, CREATE_UPDATE_API_ENDPOINT]);

  return {
    handleFetch,
    publishData,
    globalSearch,
    items,
    setItems,
    pageCount,
    setPageCount,
    currentPage,
    setcurrentPage,
    loader,
    setLoader,
    formValues,
    setFormValues,
    getImageDetails,
    imageNameState,
    setImageNameState,
    imagePreview,
    setImagePreview,
    handlePageChange,
    handleInputChange,
    handleSpecificInputChange,
    addnewList,
    toggle,
    setToggle,
    toggle2,
    setToggle2,
    toggle3,
    setToggle3,
    search,
    setSearch,
    onFileChange,
    imageManadatory,
    showImagePreview,
  };
};

export default UseGetApi;
