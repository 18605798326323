import React from "react";
import { Form } from "react-bootstrap";

const TableHead = ({
  CLabel,
  CID,
  One1,
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Link,
  Seven,
  Eight,
  Nine,
  Ten,
}) => (
  <thead>
    <tr>
      {CLabel && (
        <th>
          {CLabel}
        </th>
      )}
      {One1 && <th>{One1}</th>}
      {One && <th className="text-center">{One}</th>}
      {Two && <th className="text-center">{Two}</th>}
      {Three && <th className="text-center">{Three}</th>}
      {Four && <th className="text-center">{Four}</th>}
      {Five && <th className="text-center">{Five}</th>}
      {Six && <th className="text-center">{Six}</th>}
      {Link && <th className="text-center">{Link}</th>}
      {Seven && <th className="text-center">{Seven}</th>}
      {Eight && <th className="text-center">{Eight}</th>}
      {Nine && <th className="text-center">{Nine}</th>}
      {Ten && <th className="text-center">{Ten}</th>}
    </tr>
  </thead>
);

export default TableHead;
