import React from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { ThreeDots } from "../../Assets/indexImg";
import buttonRank from "../../styles/button.module.scss";

import EditIconUser from "../../Assets/Svg/EditIconUser";
import ExportIcon from "../../Assets/Svg/ExportIcon";
import ImportIcon from "../../Assets/Svg/ImportIcon";
import { ThreeDotsSvg } from "../../Assets/Svg/GlobalSvg";

export function ApprovalButtonRank({ showApproval }) {
  return (
    <>
      <div className={buttonRank.buttongreen}>
        <button className="btn btn-primary" onClick={showApproval}>
          Pending Approvals
        </button>
      </div>
    </>
  );
}

export const PromoteButton = ({ handleShow }) => {
  return (
    <>
      <div className={buttonRank.buttonR}>
        <button
          className={`${buttonRank.promote} btn btn-primary `}
          onClick={handleShow}
        >
          Promote Campaign
        </button>
      </div>
    </>
  );
};

export const AddButtonRank = ({ handleShow, Tittle }) => {
  return (
    <>
      <div className={buttonRank.buttonR}>
        <button
          className={`${buttonRank.add} btn btn-primary `}
          onClick={handleShow}
        >
          {Tittle}
        </button>
      </div>
    </>
  );
};
export const DownloadButtonRank = ({ ExportFile }) => {
  return (
    <>
      <div className={buttonRank.buttonR}>
        <button
          className={`${buttonRank.add} btn btn-primary `}
          onClick={() => ExportFile()}
        > 
        <i className="fa fa-download"></i>&nbsp;
          Download
        </button>
      </div>
    </>
  );
};
export const AddButtonUser = ({ handleShow }) => {
  return (
    <>
      <div>
        <button onClick={handleShow}> 
          <EditIconUser/>
        </button>
      </div>
    </>
  );
};

export const AddButtonPrivateUser = ({ handleShow }) => {
  return (
    <>
      <div>
        <button onClick={handleShow}> 
          Add <i class="fa-regular fa-plus"></i>
        </button>
      </div>
    </>
  );
};


export const ExportButtonRank = ({ ExportFile }) => {
  return (
    <>
      <div className={buttonRank.buttonR}>
        <button
          className={`${buttonRank.add} btn btn-primary `}
          onClick={() => ExportFile()}
        >
          <span>
            <ExportIcon />
          </span>
          Export
        </button>
      </div>
    </>
  );
};
const change = (e) => {
  e.target.value = null;
} 
export const ImportButtonRank = ({ SelectFiles }) => {
  return (
    <>
      <div className={buttonRank.buttonR}>
        <Form.Control
          type="file"
          accept={".csv"}
          onChange={SelectFiles}
          multiple
          className={buttonRank.upload_import}
        />

        <button
          className={`${buttonRank.import} btn btn-primary import `}
          onClick={change} 
        >
          <span>
            <ImportIcon />
          </span> 
          Import
        </button>
      </div>
    </>
  );
};

export const PlanDropdown = ({ kjhkjbkjjjjjjj, ghjfgfujhf }) => {
  return (
    <>
      <style>
        {`
          button#dropdown-plan {
            background: #eee;
            padding: 20px 20px;
            height: 50px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          {/* a.dropdown-item {
            border-bottom: 1px solid #eee;
            padding: 10px 10px;
          } */}

          .planDropdown::after {
            display: none;
          }
`}
      </style>
      <div className={buttonRank.plan_Dropdown}>
        <Dropdown>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-plan"
            className="planDropdown"
          >
            <img alt="" className="img-fluid" src={ThreeDots} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">1 Gbps</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Gamer</Dropdown.Item>
            <Dropdown.Item href="#/action-3">2 Gbps</Dropdown.Item>
            <Dropdown.Item href="#/action-3">200 Mbps</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

// export const DeclineButton = ({ kjhkjbkjjjjjjj, ghjfgfujhf }) => {
//   return (
//     <>
//       <Button
//         variant="danger"
//         className={userTabbing.decline}
//         data-uuid={uuid}
//         onClick={(e) => handleShowDecline(e)}
//       >
//         Decline
//       </Button>
//     </>
//   );
// };

export const ChangeStatusButton = ({
  Title,
  Variant,
  Class,
  Uuid,
  ShowModal,
}) => {
  return (
    <>
      <Button
        variant={Variant}
        className={Class}
        data-uuid={Uuid}
        onClick={ShowModal}
      >
        {Title}
      </Button>
    </>
  );
};

export const MenuDropdown = ({
  Title,
  Variant,
  Class,
  Uuid,
  Option1,
  Option2,
  Option3, 
  Option4, 
  Action1,
  Action2,
  Action3,
  Action4,
  PlanDropdown,
  PlanID,
  PlanClass,
}) => {
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="" id="dropdown-basic">
          <ThreeDotsSvg />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <ul className="actions">
            <li data-uuid={Uuid} onClick={Action1} role="button">
              {Option1}
            </li>
            <li data-uuid={Uuid} onClick={Action2} role="button">
              {Option2}
            </li>
            {Option3 && (
              <li data-uuid={Uuid} onClick={Action3} role="button">
                {Option3}
              </li>
            )} 
            {Option4 && (
              <li data-uuid={Uuid} onClick={Action4} role="button">
                {Option4}
              </li>
            )} 
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
