const OrganizationConstants = (uuid) => {
  const GETORGANIZATIONS_API_ENDPOINT = "admin/organizations";
  const UPDATEUSERS_API_ENDPOINT = "admin/organizations/request";
  const EXPORT_API_ENDPOINT = "admin/organizations/export";
  const IMPORT_API_ENDPOINT = "admin/organizations/import"; 
  const GETPROFILE_API_ENDPOINT = `admin/organization/${uuid}`; 
  const DELETE_API_ENDPOINT = "admin/organization";

  return {
    GETORGANIZATIONS_API_ENDPOINT,
    UPDATEUSERS_API_ENDPOINT,
    EXPORT_API_ENDPOINT,
    IMPORT_API_ENDPOINT,
    GETPROFILE_API_ENDPOINT,
    DELETE_API_ENDPOINT,
  };
};

export default OrganizationConstants;
