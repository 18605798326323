/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Bell, Slider } from "../../Assets/indexImg";
import header from "../../styles/header.module.scss";
import "../../styles/Header.scss";
import config from "../../config/config.json";
import { removeItem } from "../../utils/UniversalFunction";
import { UniversalGetService } from "../../Services/_private/UniversalGetService/universalGet.service";

const Header = ({ changeSidebar, heading, notificationPage }) => {
  const { getProfileData } = UniversalGetService;

  const [items, setItems] = useState("");

  const GETPROFILE_API_ENDPOINT = "admin/setting/profile";

  const history = useNavigate();

  const logout = () => {
    removeItem("user");

    history("/");
  };

  // FETCHING THE DATA...................

  const handleFetch = async () => {
    const data = await getProfileData(GETPROFILE_API_ENDPOINT);
    // eslint-disable-next-line
    if(data?.data?.firstName == undefined || data?.data?.lastName == undefined) {
      logout()
    }
    setItems(data?.data);
  };

  useEffect(() => {
     handleFetch();
  }, []);

  return (
    <>
      <div className={header.top_bar}>
        <Navbar bg="light" expand="lg" className={header.nav_bar}>
          <Container fluid>
            <Navbar.Brand className={header.dashboard_header}>
              <button
                className={`${header.slide} burger-open`}
                onClick={changeSidebar}
              >
                <img className="img-fluid" src={Slider} alt="" />
              </button>

              <h4>{heading}</h4>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <NavLink to="/admin/notifications">
                  <div
                    className={` ${
                      notificationPage ? header.active : header.inactive
                    } ${header.profile_im}`}
                  >
                    <img className="img-fluid" src={Bell} alt="" />
                  </div>
                </NavLink>

                {/* <img className="img-fluid" src={ProfileImage} alt="" /> */}

                <img
                  className={`d-flex justify-content-center admin_img align-items-center m-auto ${header.profileLogo}`}
                  src={config.BASE_URL + items?.image} 
                  alt=""
                />

                <NavDropdown
                  title={`${items?.firstName} ${items?.lastName}`}
                  className="profileTitle"
                  id="basic-nav-dropdown"
                >
                  {/* <NavDropdown.Divider /> */}

                  <NavDropdown.Item href="" onClick={() => logout()}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
