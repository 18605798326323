import React from "react";
import { Form } from "react-bootstrap";
import home from "../../styles/home.module.scss";
import "../../styles/searchBar.scss";
import SearchIconSvg from "../../Assets/Svg/SearchIconSvg";

const Searchbar = ({
  FilterInput,
  OnChange,
  FilterChange,
  Value1,
  Value2,
  Value3,
  Value4,
  Filter1,
  Filter2,
  Filter3,
  Filter4,
  SPH,
  FilterName,
  FilterValue,
}) => {
  return (
    <>
      <div className="searchbar">
        <div className="searchControl">
          <Form.Group
            className={`${home.login_form}`}
            controlId="formBasicPassword"
          >
            <Form.Control
              type="search"
              placeholder={SPH || "Search"}
              autoComplete="true"
              onChange={OnChange}
              required
              className={`${home.f_control} globalSearch`}
            />
          </Form.Group>
          <Form.Group className={home.forgot} controlId="formBasicCheckbox">
            <SearchIconSvg />
          </Form.Group>
        </div>

        {FilterInput && (
          <div className="filterControl">
            <Form.Select
              name={FilterName}
              value={FilterValue}
              onChange={FilterChange}
            >
              {Filter1 && <option value={Value1}>{Filter1}</option>}
              {Filter2 && <option value={Value2}>{Filter2}</option>}
              {Filter3 && <option value={Value3}>{Filter3}</option>}
              {Filter4 && <option value={Value4}>{Filter4}</option>}
            </Form.Select>
          </div>
        )}
      </div>
    </>
  );
};

export default Searchbar;
