import React from "react";
import { Button } from "react-bootstrap";
// import { SettingProfile } from "../../../../Assets/indexImg";
import "../../../../styles/UserProfile.scss";
import Loading from "../../../../components/Loading/Loading";
import "../../../../styles/CurrentOrgStyle.scss";
// import UserImageRelative from "./../../../../components/UserImageRelative/UserImageRelative";

const CurrentOrganizationProfile = ({
  loader,
  setSize,
  size,
  setName,
  name,
  updateOrgDetails,
}) => {
  return (
    <>
      <div className={"userProfileDiv"}>
        {loader ? (
          <Loading />
        ) : (
          <div className="w-100 d-flex justify-content-between currOrgMain align-items-end">
            <div className={"uploadImage orgChild"}>
              {/* <UserImageRelative SRC={SettingProfile} /> */}
              <p className="orgLabel mb-2">Organization Size</p>
              <input
                type="number"
                onChange={(e) => setSize(e.target.value)}
                className="form-control"
                value={size}
              />
            </div>

            <div className="orgChild">
              <p className="orgLabel mb-2">Organization Name</p>
              <input
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                value={name}
              />
            </div>
            {/* </div> */}

            <div className="orgChild">
              <Button className="updateDealerCode" onClick={updateOrgDetails}>
                Update
              </Button>
            </div>

            {/* <Row>
              <Col xxl={3} xl={3} lg={4} md={6} xs={12}>
                <div className={"uploadImage "}>
                  <input
                    type="file"
                    id="userProfile"
                    className="currentUserIcon"
                  />
                  <img className=" userDemoIcon" src={SettingProfile} alt="" />

                  <label
                    htmlFor="userProfile"
                    role="button"
                    className="userProfileLabel"
                  >
                    <EditIconUser />
                  </label>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} xs={12}>
                <div className="firstName">
                  <div className="firstID">
                    <p className="labels">Organization Name</p>
                    <p>{items?.name}</p>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} xs={12}>
                <div className="firstName">
                  <div>
                    <Button className="updateDealerCode">Update Dealer Code</Button>
                  </div>
                </div>
              </Col>
            </Row> */}
          </div>
        )}
      </div>
    </>
  );
};

export default CurrentOrganizationProfile;
