// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circle {
  animation: spinners linear infinite;
  position: absolute;
  border: 5px solid transparent;
  border-top-color: #f00;
  border-radius: 50%;
}

.one {
  width: 50px;
  height: 50px;
  border-top-color: #8f3b76;
  animation-duration: 0.85s;
}

.two {
  width: 70px;
  height: 70px;
  border-top-color: #c7417b;
  animation-duration: 0.95s;
}

.three {
  width: 100px;
  height: 100px;
  border-top-color: #f5487f;
  animation-duration: 1.05s;
}

@keyframes spinners {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,mCAAA;EACA,kBAAA;EACA,6BAAA;EACA,sBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,yBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,yBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,yBAAA;EACA,yBAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EAEA;IACE,yBAAA;EAAF;AACF","sourcesContent":[".spinner {\n  width: 100%;\n  height: 100vh;\n  background: #fff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n}\n\n.circle {\n  animation: spinners linear infinite;\n  position: absolute;\n  border: 5px solid transparent;\n  border-top-color: #f00;\n  border-radius: 50%;\n}\n\n.one {\n  width: 50px;\n  height: 50px;\n  border-top-color: #8f3b76;\n  animation-duration: 0.85s;\n}\n\n.two {\n  width: 70px;\n  height: 70px;\n  border-top-color: #c7417b;\n  animation-duration: 0.95s;\n}\n\n.three {\n  width: 100px;\n  height: 100px;\n  border-top-color: #f5487f;\n  animation-duration: 1.05s;\n}\n\n@keyframes spinners {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
