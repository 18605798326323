import React from "react";

export const ThreeDotsSvg = () => (
  <svg
    width="21"
    height="5"
    viewBox="0 0 21 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2.5" cy="2.5" r="2.5" fill="#474B6D" />
    <circle cx="10.5" cy="2.5" r="2.5" fill="#474B6D" />
    <circle cx="18.5" cy="2.5" r="2.5" fill="#474B6D" />
  </svg>
);

export const TrophyIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.92031 8.4605H4.68348C4.56678 8.4605 4.47217 8.36589 4.47217 8.24919C4.47217 8.13248 4.56678 8.03787 4.68348 8.03787H4.92031C5.0145 8.03787 5.09115 7.96126 5.09115 7.86706C5.09115 7.80195 5.05497 7.74344 4.99676 7.71426L3.31862 6.87437C2.54616 6.48776 1.89792 5.89497 1.44393 5.16008C0.989941 4.42519 0.75 3.58016 0.75 2.71637V2.38535C0.75 2.00349 1.06065 1.69281 1.44254 1.69281H3.94662C4.06332 1.69281 4.15793 1.78742 4.15793 1.90412C4.15793 2.02083 4.06332 2.11544 3.94662 2.11544H1.44251C1.29368 2.11544 1.1726 2.23651 1.1726 2.38535V2.71637C1.1726 3.50165 1.39075 4.26988 1.80347 4.93797C2.2162 5.60608 2.80554 6.14497 3.50778 6.49645L5.18592 7.33634C5.38813 7.43757 5.51375 7.64092 5.51375 7.86703C5.51375 8.19428 5.24756 8.4605 4.92031 8.4605Z"
      fill="#F7A738"
    />
    <path
      d="M9.84394 8.4605H9.60711C9.27989 8.4605 9.01367 8.19428 9.01367 7.86706C9.01367 7.64092 9.13929 7.4376 9.3415 7.33637L11.0197 6.49645C11.7219 6.14497 12.3112 5.60608 12.724 4.93797C13.1367 4.26988 13.3548 3.50165 13.3548 2.71637V2.38535C13.3548 2.23651 13.2338 2.11544 13.0849 2.11544H10.5809C10.4641 2.11544 10.3695 2.02083 10.3695 1.90412C10.3695 1.78742 10.4641 1.69281 10.5809 1.69281H13.0849C13.4668 1.69281 13.7775 2.00346 13.7775 2.38535V2.71637C13.7775 3.58016 13.5375 4.42519 13.0835 5.16008C12.6296 5.89497 11.9813 6.48776 11.2089 6.87437L9.53072 7.71426C9.4725 7.74341 9.43635 7.80192 9.43635 7.86703C9.43635 7.96123 9.513 8.03785 9.6072 8.03785H9.84402C9.96072 8.03785 10.0553 8.13246 10.0553 8.24916C10.0553 8.36589 9.96064 8.4605 9.84394 8.4605Z"
      fill="#F7A738"
    />
    <path
      d="M7.98555 9.29991V8.38055H6.54185V9.29991C6.54185 9.70664 6.31252 10.0786 5.94915 10.2613L5.80404 10.3343C5.52961 10.4723 5.35645 10.7532 5.35645 11.0603V12.1366H9.17098V11.0603C9.17098 10.7532 8.99781 10.4723 8.72339 10.3343L8.57828 10.2613C8.21488 10.0786 7.98555 9.70664 7.98555 9.29991Z"
      fill="#FEC93E"
    />
    <path
      d="M8.72336 10.3343L8.57825 10.2613C8.21485 10.0786 7.98555 9.70664 7.98555 9.29991V8.38055H7.26367V12.1366H9.17095V11.0603C9.17095 10.7532 8.99776 10.4723 8.72336 10.3343Z"
      fill="#EFB635"
    />
    <path
      d="M7.26385 9.15951C5.5118 8.4582 4.3125 6.81987 4.17346 4.9378L3.85059 0.566956H10.6771L10.3543 4.9378C10.2152 6.81987 9.0159 8.4582 7.26385 9.15951Z"
      fill="#FEC93E"
    />
    <path
      d="M9.3204 0.566956L8.99749 4.9378C8.89821 6.28161 8.25831 8.01955 7.2627 9.1591C7.26302 9.15924 7.26335 9.15938 7.26368 9.15951C9.01573 8.4582 10.215 6.81987 10.3541 4.9378L10.677 0.566956H9.3204Z"
      fill="#EFB635"
    />
    <path
      d="M10.996 0.970293H3.53062C3.38535 0.970293 3.26758 0.852524 3.26758 0.707246V0.263047C3.26758 0.11777 3.38535 0 3.53062 0H10.996C11.1413 0 11.2591 0.11777 11.2591 0.263047V0.707219C11.2591 0.852496 11.1413 0.970293 10.996 0.970293Z"
      fill="#F7A738"
    />
    <path
      d="M10.996 0.483275H3.53065C3.39259 0.483275 3.27961 0.37688 3.26867 0.241638C3.2681 0.24872 3.26758 0.25583 3.26758 0.263048V0.70722C3.26758 0.852498 3.38535 0.970267 3.53063 0.970267H10.996C11.1413 0.970267 11.2591 0.852498 11.2591 0.70722V0.263048C11.2591 0.25583 11.2585 0.24872 11.258 0.241638C11.2471 0.37688 11.1341 0.483275 10.996 0.483275Z"
      fill="#EA9632"
    />
    <path
      d="M10.5952 13.249H3.93262V12.0419C3.93262 11.6436 4.25549 11.3207 4.65378 11.3207H9.87411C10.2724 11.3207 10.5953 11.6436 10.5953 12.0419V13.249H10.5952Z"
      fill="#F7A738"
    />
    <path
      d="M9.87409 11.3207H9.11918V12.173C9.11918 12.469 8.87918 12.7091 8.58311 12.7091H3.93262V13.2491H10.5952V12.7091V12.0419C10.5952 11.6436 10.2723 11.3207 9.87409 11.3207Z"
      fill="#EA9632"
    />
    <path
      d="M11.1652 14H3.36155C3.31613 14 3.2793 13.9632 3.2793 13.9178V13.1784C3.2793 13.133 3.31613 13.0961 3.36155 13.0961H11.1652C11.2107 13.0961 11.2475 13.133 11.2475 13.1784V13.9178C11.2475 13.9632 11.2107 14 11.1652 14Z"
      fill="#FEC93E"
    />
    <path
      d="M8.13695 9.41067H6.38988C6.17187 9.41067 5.99512 9.23392 5.99512 9.01591V8.8886C5.99512 8.67058 6.17187 8.49384 6.38988 8.49384H8.13695C8.35497 8.49384 8.53172 8.67058 8.53172 8.8886V9.01591C8.53172 9.23395 8.35497 9.41067 8.13695 9.41067Z"
      fill="#FF8150"
    />
    <path
      d="M8.13695 8.95225H6.38988C6.23107 8.95225 6.09451 8.85824 6.03187 8.72302C6.00852 8.77342 5.99512 8.82936 5.99512 8.88856V9.01587C5.99512 9.23389 6.17187 9.41064 6.38988 9.41064H8.13695C8.35497 9.41064 8.53172 9.23389 8.53172 9.01587V8.88856C8.53172 8.82936 8.51832 8.77342 8.49497 8.72302C8.43232 8.85824 8.29577 8.95225 8.13695 8.95225Z"
      fill="#FD6E2E"
    />
    <path
      d="M7.32867 2.02535L7.81782 3.37602L9.25356 3.42384C9.31812 3.426 9.34467 3.50768 9.2937 3.54738L8.1603 4.42998L8.55848 5.81021C8.57639 5.87228 8.50688 5.92276 8.4534 5.88656L7.26373 5.08136L6.07408 5.88656C6.0206 5.92276 5.95109 5.87228 5.969 5.81021L6.36718 4.42998L5.23378 3.54738C5.18281 3.5077 5.20936 3.426 5.27392 3.42384L6.70966 3.37602L7.19881 2.02535C7.22077 1.96461 7.30668 1.96461 7.32867 2.02535Z"
      fill="#F2EBDA"
    />
    <path
      d="M11.1652 13.0961H9.77144V13.3884C9.77144 13.5611 9.63144 13.7011 9.45874 13.7011H3.36155C3.31613 13.7011 3.2793 13.6642 3.2793 13.6188V13.9178C3.2793 13.9632 3.31613 14 3.36155 14H11.1652C11.2107 14 11.2475 13.9632 11.2475 13.9178V13.6188V13.1784C11.2475 13.133 11.2107 13.0961 11.1652 13.0961Z"
      fill="#EFB635"
    />
  </svg>
);

export const TrendUpIcon = () => (
  <svg
    width="15"
    height="8"
    viewBox="0 0 15 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9834 0.5C14.9834 0.367392 14.9307 0.240215 14.8369 0.146447C14.7432 0.0526785 14.616 0 14.4834 0H10.3584C10.2258 0 10.0986 0.0526785 10.0048 0.146447C9.91105 0.240215 9.85837 0.367392 9.85837 0.5C9.85837 0.632608 9.91105 0.759785 10.0048 0.853553C10.0986 0.947321 10.2258 1 10.3584 1H13.2659L9.11837 5.1L5.33587 2.405C5.2388 2.33584 5.12022 2.30362 5.0015 2.31417C4.88278 2.32471 4.77173 2.37732 4.68837 2.4625L0.12587 7.15C0.0406323 7.24614 -0.00432374 7.3714 0.000328132 7.49981C0.00498001 7.62821 0.0588828 7.74989 0.150854 7.83961C0.242825 7.92933 0.365806 7.9802 0.494282 7.98167C0.622759 7.98314 0.746871 7.93509 0.84087 7.8475L5.10587 3.4675L8.88087 6.1575C8.97695 6.22595 9.09413 6.25822 9.21171 6.24862C9.32929 6.23901 9.43968 6.18814 9.52337 6.105L13.9834 1.6975V4.625C13.9834 4.75761 14.036 4.88479 14.1298 4.97855C14.2236 5.07232 14.3508 5.125 14.4834 5.125C14.616 5.125 14.7432 5.07232 14.8369 4.97855C14.9307 4.88479 14.9834 4.75761 14.9834 4.625V0.5Z"
      fill="white"
    />
  </svg>
);
