import React from "react";
import EditIconUser from "../../Assets/Svg/EditIconUser";

const UserImageRelative = ({ SRC, UploadOnChange }) => (
  <>
    <div className="userImagerelative">
      <img className="userDemoIcon" src={SRC} alt="" />

      <label
        htmlFor="userProfile"
        role="button"
        className="userProfileLabel userImageabsolute"
      >
        <input type="file" id="userProfile" className="currentUserIcon" onChange={UploadOnChange} />
        <EditIconUser />
      </label>
    </div>
  </>
);

export default UserImageRelative;
