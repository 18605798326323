import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import UsersConstants from "../Constants/UsersConstants/UsersConstants";
import { toastProps } from "../Services/_apiResponses/apiResponse.service";
import { UniversalCreateUpdateService } from "../Services/_private/UniversalCreateUpdateService/universalCreateUpdate.service";
import { UniversalGetService } from "../Services/_private/UniversalGetService/universalGet.service";
import UseToggle from "./UseToggle";
import UseExportImport from "./UseExportImport";

const TopHook = (GETUSERS_API_ENDPOINT) => {
  const { universalGetList } = UniversalGetService; 
  const { universalCreateUpdate, uploadImages } = UniversalCreateUpdateService;
  const { toggle, setToggle } = UseToggle();
  const { toggle2, setToggle2 } = UseToggle();
  const { toggle3, setToggle3 } = UseToggle(); 
  const { handleDownload } = UseExportImport(); 
  const navigate = useNavigate();
  const [uuid, setUuid] = useState("");
  const [declineReason, setDeclineReason] = useState("");
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);
  const [startDateValue, setStartDateValue] = useState(null); // Adjust initial value if necessary
  const [endDateValue, setEndDateValue] = useState(null); // Adjust initial value if necessary 
  const { DELETE_API_ENDPOINT } = UsersConstants(uuid); 
  const handleFetch = async (skip, GET_API_ENDPOINT, filters) => {
    if (!GET_API_ENDPOINT) {
      return false;
    }
    const skipValue = Number(skip) * 10 || 0; // Default to 0 if NaN 
    let obj = {
      search,
      skip: skipValue,
      limit: 10,
      awardId: undefined,  // Replace with actual value if needed
      type: undefined,     // Replace with actual value if needed
      ...filters
    };
    // Remove null or undefined values from filters
    Object.keys(obj).forEach(key => (obj[key] === null || obj[key] === undefined) && delete obj[key]);

    try {
      const data = await universalGetList(obj, GET_API_ENDPOINT);
      setItems(data.teams || []);
      setPageCount(data.totalPage || 1);

    } catch (error) { 
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  const globalSearch = (value) => {
    if (value !== undefined) {
      setSearch(value);
      setLoader(true);
    }
  };

  useEffect(() => {
    handleFetch(currentPage, GETUSERS_API_ENDPOINT, {
      startDate: startDateValue,
      endDate: endDateValue,
      filterCategory: ""
    });
  }, [GETUSERS_API_ENDPOINT, currentPage, search, startDateValue, endDateValue]);

  const handleShowDecline = (e) => {
    let uuid = e.target.getAttribute("data-uuid");
    setUuid(uuid);
    setToggle2();  
  };

  const handleShowApprove = (e) => {
    let uuid = e.target.getAttribute("data-uuid");
    setUuid(uuid);
    setToggle();  
  };
  const handlePageChange = (e) => { 
    setCurrentPage(Number(e.selected) || 0);
    setLoader(true);
  };
  console.log("currentPage:", currentPage); 
  console.log("skip:", Number(currentPage) * 10);  

  return {
    toggle,
    setToggle,
    toggle2,
    setToggle2,
    toggle3,
    setToggle3,
    handleFetch,
    handleDownload,
    uuid,
    globalSearch,
    handlePageChange,
    items,
    pageCount,
    loader,
    setLoader,
    handleShowDecline,
    navigate,
    handleShowApprove,
    // Add other functions as needed
  };
};

export default TopHook;
