// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadImage .orgChild,
.currOrgMain .orgChild {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 33%;
}
.uploadImage .orgLabel,
.currOrgMain .orgLabel {
  opacity: 0.4;
  font-size: 12px;
  padding-top: 0px;
}
.uploadImage .userImagerelative,
.currOrgMain .userImagerelative {
  position: relative;
  display: inline-block;
}
.uploadImage .userImageabsolute,
.currOrgMain .userImageabsolute {
  position: absolute;
  top: -10px;
  right: 20px;
}

.custUserInput .firstID {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  width: 100%;
}

@media (min-width: 320px) and (max-width: 1060px) {
  .userDemoIcon {
    height: 149px;
  }
}
@media (min-width: 320px) and (max-width: 900px) {
  .currOrgMain {
    display: flex;
    flex-direction: column;
  }
  .currOrgMain .orgChild {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 252px; */
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/CurrentOrgStyle.scss"],"names":[],"mappings":"AAEE;;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,UAAA;AAAJ;AAGE;;EACE,YAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAGE;;EACE,kBAAA;EACA,qBAAA;AAAJ;AAGE;;EACE,kBAAA;EACA,UAAA;EACA,WAAA;AAAJ;;AAGA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,WAAA;AAAJ;;AAEA;EACE;IACE,aAAA;EACF;AACF;AAEA;EACE;IACE,aAAA;IACA,sBAAA;EAAF;EAEE;IACE,mBAAA;IACA,aAAA;IACA,sBAAA;IACA,uBAAA;IACA,mBAAA;IACA,mBAAA;IACA,WAAA;EAAJ;AACF","sourcesContent":[".uploadImage,\n.currOrgMain {\n  .orgChild {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    width: 33%;\n  }\n\n  .orgLabel {\n    opacity: 0.4;\n    font-size: 12px;\n    padding-top: 0px;\n  }\n\n  .userImagerelative {\n    position: relative;\n    display: inline-block;\n  }\n\n  .userImageabsolute {\n    position: absolute;\n    top: -10px;\n    right: 20px;\n  }\n}\n.custUserInput .firstID {\n    display: flex;\n    flex-direction: column;\n    max-width: 200px;\n    width: 100%;\n}\n@media (min-width: 320px) and (max-width: 1060px) {\n  .userDemoIcon {\n    height: 149px;\n  }\n}\n\n@media (min-width: 320px) and (max-width: 900px) {\n  .currOrgMain {\n    display: flex;\n    flex-direction: column;\n\n    .orgChild {\n      margin-bottom: 20px;\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      align-items: center;\n      /* height: 252px; */\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
