import React from "react";
import home from "../../styles/home.module.scss";

const SearchIconSvg = () => {
  return (
    <>
      <span role="button" className={`${home.eye} hello `}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.13116 13.4573C2.68743 10.3188 2.90816 5.77813 5.79334 2.89295C8.91754 -0.231244 13.9829 -0.231244 17.107 2.89295C20.2312 6.01714 20.2312 11.0825 17.107 14.2067C14.2219 17.0918 9.6812 17.3126 6.54272 14.8688C6.52918 14.8841 6.51509 14.8991 6.50045 14.9138L2.25781 19.1564C1.86728 19.5469 1.23412 19.5469 0.843594 19.1564C0.45307 18.7659 0.45307 18.1327 0.843594 17.7422L5.08623 13.4996C5.10088 13.4849 5.11586 13.4708 5.13116 13.4573ZM7.20755 4.30716C4.86441 6.65031 4.86441 10.4493 7.20755 12.7924C9.5507 15.1356 13.3497 15.1356 15.6928 12.7924C18.036 10.4493 18.036 6.65031 15.6928 4.30716C13.3497 1.96402 9.5507 1.96402 7.20755 4.30716Z"
            fill="#474B6D"
          />
        </svg>
      </span>
    </>
  );
};

export default SearchIconSvg;
