import React from "react";
import { NavLink } from "react-router-dom";

const UsersMainTabbing = ({ UsersMainTabPage, OrganizationsMainTabPage }) => (
  <>
    <style>
      {`
        .nav-tabs .nav-link.active {
          background: #7F00FF;
          color: #fff;
        }
    `}
    </style>

    <div className="d-flex">
      <NavLink
        to="/admin/users"
        className={
          UsersMainTabPage ? "usersmaintabbibgActive" : "usersmaintabbibg"
        }
      >
        Users
      </NavLink>

      <NavLink
        to="/admin/organizations"
        className={
          OrganizationsMainTabPage
            ? "usersmaintabbibgActive"
            : "usersmaintabbibg"
        }
      >
        Organisations
      </NavLink>
    </div>
  </>
);

export default UsersMainTabbing;
