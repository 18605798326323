import React from "react";

const ImportIcon = () => {
  return (
    <>
      <svg
        className="importIcon"
        width="19"
        height="15"
        viewBox="0 0 19 15"
        fill="#474B6D"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.000176048 8.18388L0.000176563 2.29239C0.0236446 1.66365 0.295003 1.06975 0.754964 0.640457C1.21493 0.211159 1.8261 -0.0186457 2.45496 0.00125177L15.5472 0.00125291C16.176 -0.0186445 16.7872 0.211161 17.2472 0.640458C17.7071 1.06976 17.9785 1.66365 18.002 2.29239L18.002 8.18388C18.002 8.4009 17.9157 8.60902 17.7623 8.76248C17.6088 8.91593 17.4007 9.00214 17.1837 9.00214C16.9667 9.00214 16.7585 8.91593 16.6051 8.76248C16.4516 8.60902 16.3654 8.4009 16.3654 8.18388L16.3654 2.29239C16.3389 2.09922 16.2392 1.92361 16.0869 1.80181C15.9347 1.68 15.7415 1.62128 15.5472 1.63778L2.45496 1.63778C2.26068 1.62128 2.06747 1.68 1.91521 1.80181C1.76295 1.92361 1.66326 2.09922 1.6367 2.29239L1.6367 8.18388C1.6367 8.40089 1.55049 8.60902 1.39704 8.76248C1.24358 8.91593 1.03545 9.00214 0.818438 9.00214C0.601422 9.00214 0.393292 8.91593 0.239838 8.76248C0.0863844 8.60902 0.000176029 8.40089 0.000176048 8.18388ZM10.9731 10.7778L9.81933 11.9397L9.81933 4.91083C9.81933 4.69381 9.73312 4.48568 9.57966 4.33223C9.42621 4.17878 9.21808 4.09257 9.00107 4.09257C8.78405 4.09257 8.57592 4.17877 8.42247 4.33223C8.26901 4.48568 8.1828 4.69381 8.1828 4.91083L8.1828 11.9397L7.02905 10.7778C6.95298 10.7011 6.86248 10.6402 6.76277 10.5987C6.66306 10.5571 6.55611 10.5357 6.44809 10.5357C6.34006 10.5357 6.23311 10.5571 6.1334 10.5987C6.03369 10.6402 5.94319 10.7011 5.86712 10.7778C5.79128 10.8542 5.73128 10.9449 5.69056 11.0446C5.64984 11.1443 5.6292 11.251 5.62982 11.3587C5.6292 11.4664 5.64984 11.5732 5.69056 11.6729C5.73128 11.7726 5.79128 11.8632 5.86712 11.9397L8.4201 14.4927C8.56021 14.6325 8.74627 14.7168 8.94379 14.73L9.09926 14.73C9.26296 14.7105 9.41698 14.6421 9.54112 14.5336L9.58203 14.5336L12.135 11.9397C12.2891 11.7856 12.3757 11.5766 12.3757 11.3587C12.3757 11.1408 12.2891 10.9319 12.135 10.7778C11.9809 10.6237 11.7719 10.5371 11.554 10.5371C11.3361 10.5371 11.1272 10.6237 10.9731 10.7778Z" />
      </svg>
    </>
  );
};

export default ImportIcon;
