import React from "react";

import buttonRank from "../../styles/button.module.scss";

const ExportIcon = () => {
  return (
    <>
      <svg
        className={`${buttonRank.exportIcon}`}
        width="19"
        height="15"
        viewBox="0 0 19 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.0019 6.54615V12.4377C17.9785 13.0664 17.7071 13.6603 17.2471 14.0896C16.7872 14.5189 16.176 14.7487 15.5471 14.7288L2.45481 14.7288C1.82594 14.7487 1.21476 14.5189 0.754795 14.0896C0.294829 13.6603 0.0234688 13.0664 0 12.4377L0 6.54615C0 6.32913 0.0862102 6.12101 0.239665 5.96755C0.393121 5.81409 0.601251 5.72788 0.818269 5.72788C1.03529 5.72788 1.24342 5.81409 1.39687 5.96755C1.55033 6.12101 1.63654 6.32913 1.63654 6.54615L1.63654 12.4377C1.6631 12.6309 1.76279 12.8065 1.91505 12.9283C2.06731 13.0501 2.26052 13.1088 2.45481 13.0923L15.5471 13.0923C15.7414 13.1088 15.9346 13.0501 16.0869 12.9283C16.2391 12.8065 16.3388 12.6309 16.3654 12.4377V6.54615C16.3654 6.32913 16.4516 6.12101 16.605 5.96755C16.7585 5.81409 16.9666 5.72788 17.1837 5.72788C17.4007 5.72788 17.6088 5.81409 17.7623 5.96755C17.9157 6.12101 18.0019 6.32913 18.0019 6.54615ZM7.02893 3.95224L8.18269 2.7903L8.18269 9.81923C8.18269 10.0362 8.2689 10.2444 8.42236 10.3978C8.57581 10.5513 8.78394 10.6375 9.00096 10.6375C9.21798 10.6375 9.42611 10.5513 9.57956 10.3978C9.73302 10.2444 9.81923 10.0362 9.81923 9.81923L9.81923 2.7903L10.973 3.95224C11.0491 4.02893 11.1396 4.08981 11.2393 4.13135C11.339 4.17289 11.4459 4.19428 11.554 4.19428C11.662 4.19428 11.7689 4.17289 11.8686 4.13135C11.9684 4.08981 12.0589 4.02893 12.1349 3.95224C12.2108 3.87578 12.2708 3.7851 12.3115 3.68541C12.3522 3.58571 12.3729 3.47896 12.3722 3.37127C12.3729 3.26358 12.3522 3.15683 12.3115 3.05713C12.2708 2.95743 12.2108 2.86676 12.1349 2.7903L9.58193 0.237298C9.44182 0.0974561 9.25576 0.0131486 9.05824 0L8.90277 0C8.73906 0.0194545 8.58505 0.0879054 8.4609 0.196385H8.41999L5.86699 2.7903C5.71291 2.94438 5.62634 3.15336 5.62634 3.37127C5.62634 3.58918 5.71291 3.79816 5.86699 3.95224C6.02107 4.10632 6.23005 4.19289 6.44796 4.19289C6.66587 4.19289 6.87485 4.10632 7.02893 3.95224Z" />
      </svg>
    </>
  );
};

export default ExportIcon;
