import { useReducer } from "react";

const UseToggle = () => {
  const [toggle, setToggle] = useReducer((show) => !show, false);
  const [toggle1, setToggle1] = useReducer((show) => !show, false);
  const [toggle2, setToggle2] = useReducer((show) => !show, false);
  const [toggle3, setToggle3] = useReducer((show) => !show, false);
  const [toggle4, setToggle4] = useReducer((show) => !show, false);
  const [toggle5, setToggle5] = useReducer((show) => !show, false);

  const togglePassword = (type) => (type ? "text" : "password");

  return {
    toggle,
    setToggle,
    toggle1,
    setToggle1,
    toggle2,
    setToggle2,
    toggle3,
    setToggle3,
    toggle4,
    setToggle4,
    toggle5,
    setToggle5,
    togglePassword,
  };
};

export default UseToggle;
