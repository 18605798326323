// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileTitle #basic-nav-dropdown:hover {
  color: #7f00ff;
}`, "",{"version":3,"sources":["webpack://./src/styles/Header.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF","sourcesContent":[".profileTitle #basic-nav-dropdown:hover {\n  color: #7f00ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
