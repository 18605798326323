export { default as Logo } from "./Image/MppLogo1.png";
export { default as LoginBg } from "./Image/logn_bg.png";
export { default as Bell } from "./Image/bell.svg";
export { default as ProfileImage } from "./Image/profile_image.png";
export { default as Trophy } from "./Image/trophy.svg";
export { default as TrendUp } from "./Image/up.svg";
export { default as ThreeDots } from "./Image/three_dots.svg";
export { default as Star } from "./Image/star.svg";
export { default as Export } from "./Image/export.svg";
export { default as Import } from "./Image/import.svg";
export { default as SettingProfile } from "./Image/setting_profile.png";
export { default as DummyImage } from "./Image/dummyImage.jpg";
export { default as Eye } from "./Image/eye.svg";
export { default as UserProfile } from "./Image/user_profile.png";
export { default as CalendarIcon } from "./Image/calendar.png";
export { default as PageNotFoundIcon } from "./Image/PageNotFound.png";

export { default as Dashboard } from "./SidebarIcons/dashboard.svg";
export { default as User } from "./SidebarIcons/user.svg";
export { default as Rank } from "./SidebarIcons/rank.svg";
export { default as Competition } from "./SidebarIcons/competition.svg";
export { default as Redemtion } from "./SidebarIcons/redemtion.svg";
export { default as Badges } from "./SidebarIcons/badges.svg";
export { default as Report } from "./SidebarIcons/report.svg";
export { default as Plan } from "./SidebarIcons/plans.svg";
export { default as Order } from "./SidebarIcons/orders.svg";
export { default as Setting } from "./SidebarIcons/setting.svg";
export { default as Logout } from "./SidebarIcons/logout.svg";
export { default as Slider } from "./SidebarIcons/slider.svg";
