const UsersConstants = () => {
  const GETUSERS_API_ENDPOINT = "admin/users";
  const UPDATEUSERS_API_ENDPOINT = "admin/users/request";
  const EXPORT_API_ENDPOINT = "admin/users/export";
  const IMPORT_API_ENDPOINT = "admin/users/import";
  const DELETE_API_ENDPOINT = "admin/user";

  return {
    GETUSERS_API_ENDPOINT,
    UPDATEUSERS_API_ENDPOINT,
    EXPORT_API_ENDPOINT,
    IMPORT_API_ENDPOINT,
    DELETE_API_ENDPOINT,
  };
};

export default UsersConstants;
