import { toast } from "react-toastify";
import swal from "sweetalert";
import { authHeader } from "../../Helpers";

// HEADERS.................

let headerSet = authHeader();
const headers = {
  authorization: headerSet.Authorization,
  "content-type": "application/json",
};

// HEADER WITH AUTH.................

const headersWithAuth = (method, userData, headers) => ({
  method: method,
  headers: headers,
  body: JSON.stringify(userData),
});

// HEADERS WITHOUT BODY.................

const headersWithAuthWithoutBody = (method, headers) => ({
  method: method,
  headers: headers,
});

// HANDLE RESPONSE.................

export const toastProps = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

const handleResponse = (res) =>
  // res.statusCode === 200 &&
  // toast.success(`${res.customMessage || "Success"}`, toastProps);

  res.statusCode === 200
    ? toast.success(`${res.customMessage || "Success"}`, toastProps)
    : toast.error(
        `${res.customMessage || "Please try again later..!"}`,
        toastProps
      );

// const handleResponse = (res) => {
//   return res.statusCode === 200
//     ? successAlert(res.customMessage, res.customMessage, "success")
//     : alert(res.customMessage, "Please try again later..!", "error");
// };

// ALERT.................

const alert = (message, message2, statusCode) => {
  swal({
    title: message,
    text: message2,
    type: statusCode,
    icon: statusCode,
    timer: 2000,
  });
  return false;
};

// SWEET ALERT.................

const successAlert = (message, message2, statusCode) => {
  swal({
    title: message,
    text: message2,
    icon: statusCode,
    // icon: statusCode,
    timer: 2000,
  });
  return true;
};

export const ApiResponse = {
  headers,
  headersWithAuth,
  headersWithAuthWithoutBody,
  handleResponse,
  successAlert,
  alert,
};
