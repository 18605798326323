import React from "react";

const EditIconUser = () => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_828_3579)">
          <rect width="16" height="16" fill="white" />
          <path
            d="M12.437 0.620374L4.30902 8.74837C3.99858 9.05714 3.75247 9.42441 3.58494 9.82894C3.41741 10.2335 3.33178 10.6672 3.33302 11.105V12.0004C3.33302 12.1772 3.40326 12.3468 3.52828 12.4718C3.65331 12.5968 3.82288 12.667 3.99969 12.667H4.89502C5.33286 12.6683 5.7666 12.5827 6.17113 12.4151C6.57565 12.2476 6.94292 12.0015 7.25169 11.691L15.3797 3.56304C15.7693 3.17249 15.9881 2.64336 15.9881 2.09171C15.9881 1.54006 15.7693 1.01093 15.3797 0.620374C14.9835 0.241636 14.4565 0.0302734 13.9084 0.0302734C13.3602 0.0302734 12.8332 0.241636 12.437 0.620374ZM14.437 2.62037L6.30902 10.7484C5.9331 11.122 5.42503 11.3323 4.89502 11.3337H4.66635V11.105C4.66774 10.575 4.87807 10.067 5.25169 9.69104L13.3797 1.56304C13.5221 1.42701 13.7114 1.3511 13.9084 1.3511C14.1053 1.3511 14.2946 1.42701 14.437 1.56304C14.577 1.70338 14.6556 1.8935 14.6556 2.09171C14.6556 2.28991 14.577 2.48003 14.437 2.62037Z"
            fill="#474B6D"
          />
          <path
            d="M15.3333 5.986C15.1565 5.986 14.987 6.05624 14.8619 6.18126C14.7369 6.30629 14.6667 6.47586 14.6667 6.65267V10H12C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12V14.6667H3.33333C2.8029 14.6667 2.29419 14.456 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V3.33333C1.33333 2.8029 1.54405 2.29419 1.91912 1.91912C2.29419 1.54405 2.8029 1.33333 3.33333 1.33333H9.36133C9.53815 1.33333 9.70771 1.2631 9.83274 1.13807C9.95776 1.01305 10.028 0.843478 10.028 0.666667C10.028 0.489856 9.95776 0.320286 9.83274 0.195262C9.70771 0.0702379 9.53815 0 9.36133 0L3.33333 0C2.4496 0.00105857 1.60237 0.352588 0.97748 0.97748C0.352588 1.60237 0.00105857 2.4496 0 3.33333L0 12.6667C0.00105857 13.5504 0.352588 14.3976 0.97748 15.0225C1.60237 15.6474 2.4496 15.9989 3.33333 16H10.8953C11.3333 16.0013 11.7671 15.9156 12.1718 15.7481C12.5764 15.5806 12.9438 15.3345 13.2527 15.024L15.0233 13.252C15.3338 12.9432 15.58 12.576 15.7477 12.1715C15.9153 11.767 16.0011 11.3332 16 10.8953V6.65267C16 6.47586 15.9298 6.30629 15.8047 6.18126C15.6797 6.05624 15.5101 5.986 15.3333 5.986ZM12.31 14.0813C12.042 14.3487 11.7031 14.5337 11.3333 14.6147V12C11.3333 11.8232 11.4036 11.6536 11.5286 11.5286C11.6536 11.4036 11.8232 11.3333 12 11.3333H14.6167C14.5342 11.7023 14.3493 12.0406 14.0833 12.3093L12.31 14.0813Z"
            fill="#474B6D"
          />
        </g>
        <defs>
          <clipPath id="clip0_828_3579">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default EditIconUser;
