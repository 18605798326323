import React from "react";
import ImportIcon from "../../Assets/Svg/ImportIcon";
import EditIconUser from "../../Assets/Svg/EditIconUser";
import tabbingModule from "../../styles/tabbing.module.scss";
import config from "../../config/config.json"; 

const ImportComponent = ({ UploadValue, UploadOnChange, imageNameState   }) => {
  const imageSelectedClass = `text-center w-100 d-flex justify-content-center align-items-center mt-1 ${
    imageNameState === "" && "text-danger"
  }`;

  return (
    
    <div className="x">  
      <div className={`${tabbingModule.uploadDiv}`}> 
        
        <label htmlFor="uploadImage" role="button" className="cust_pop_icon">
          <span className={`${tabbingModule.uploadIcon}`}>
            <EditIconUser />
          </span>

        </label>
        <p>Upload Image</p>

        <input
          type="file"
          id="uploadImage"
          name="uploadImage"
          // value={UploadValue}
          accept="image/*"
          className={`${tabbingModule.uploadInput} `}
          onChange={(e) => UploadOnChange(e)}
        />
      </div>

      {/*<span className={imageSelectedClass}>
        {imageNameState || "No Image Selected !"}
      </span>*/}
    </div>
  );
};

export default ImportComponent;
