import { saveAs } from "file-saver";
import config from "../config/config.json";
import { ExportImportService } from "../Services/_private/exportImport/exportImport.service";
import { UniversalCreateUpdateService } from "../Services/_private/UniversalCreateUpdateService/universalCreateUpdate.service";

const UseExportImport = () => {
  const { uploadImages } = UniversalCreateUpdateService;
  const { importFile, exportFile, exportByPayloadService } =
    ExportImportService;

  // DOWNLOAD FILE.............................

  const handleDownload = async (endpoint) => {
    let response = await exportFile(endpoint);
    let url = config.BASE_URL + response?.data;
    saveAs(url, url);
  };

  // UPLOAD FILE.............................

  const onFileChange = async (e, endpoint) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    // await importFile(formData, endpoint);
    await uploadImages(formData, endpoint);
    console.log("formdataaaaa", formData);
  };

  // UPLOAD REDEMPTION IMAGE.............................

  const onImageChange = (e, endpoint) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("jpg", e.target.files[0], e.target.files[0].name);
    importFile(formData, endpoint);
    console.log("formssssssssssssssss", formData);
  };

  // EXPORT BY PAYLOAD.............................

  const exportByPayload = async (userdata, endpoint) => {
    let response = await exportByPayloadService(userdata, endpoint);

    let url = config.BASE_URL + response?.data;
    saveAs(url, url);
  };

  return {
    handleDownload,
    onFileChange,
    onImageChange,
    exportByPayload,
  };
};

export default UseExportImport;
