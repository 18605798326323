import { toast } from "react-toastify";
import config from "../../../config/config.json";
import {
  ApiResponse,
  toastProps,
} from "../../_apiResponses/apiResponse.service";

const { headers, headersWithAuth, handleResponse, headersWithAuthWithoutBody } =
  ApiResponse;

// EXPORT......................

const exportFile = async (endpoint) => {
  try {
    const response = await fetch(
      `${config.API_URL}${endpoint}`,
      headersWithAuthWithoutBody("POST", headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
  } catch (error) {
    toast.error(`${error || "Please try again later..!"}`, toastProps);
  }
};

// IMPORT......................

const importFile = async (importedFile, endpoint) => {
  try {
    const response = await fetch(
      `${config.API_URL}${endpoint}`,
      headersWithAuth("POST", importedFile, headers)
    );
    const data = await response.json();
    return handleResponse(data);
  } catch (error) {
    toast.error(`${error || "Please try again later..!"}`, toastProps);
  }
};

// EXPORT ORDERS.......................

const exportByPayloadService = async (userData, endpoint) => {
  try {
    const response = await fetch(
      `${config.API_URL}${endpoint}`,
      headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
  } catch (error) {
    toast.error(`${error || "Please try again later..!"}`, toastProps);
  }
};

export const ExportImportService = {
  importFile,
  exportFile,
  exportByPayloadService,
};
