import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import userModule from "../../../styles/user.module.scss";
import UseToggle from "../../../Hooks/UseToggle";
import OrganisationsRequestsTable from "../../User/Organisations/OrganisationsRequestsTable";

export default function Organizations() {
  const { toggle, setToggle } = UseToggle();

  return (
    <>
      <div className={userModule.user_main}>
        <Sidebar sidebar={toggle} UserPage="UserPage" />

        <div className={userModule.page_content}>
          <Header heading="Organisations" changeSidebar={setToggle} />

          <div className={userModule.inner_content}>
            <Container fluid>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <div className={userModule.rank_tab_button}>
                    <OrganisationsRequestsTable />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
