// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select.form-select.promoteSelect {
  border-top: none;
  border-radius: 0;
  border-right: none;
  border-left: none;
  padding-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/styles/promoteModal.mudule.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,oBAAA;AACF","sourcesContent":["select.form-select.promoteSelect {\n  border-top: none;\n  border-radius: 0;\n  border-right: none;\n  border-left: none;\n  padding-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
