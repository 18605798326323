import { useState } from "react";
import React from "react";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Form, Button } from "react-bootstrap";
import config from "../../config/config.json"; 
import { DummyImage } from "../../Assets/indexImg";
import tabbingModule from "../../styles/tabbing.module.scss";
import "../../styles/promoteModal.mudule.scss";
import UserImageRelative from "../../components/UserImageRelative/UserImageRelative"; 
import ImportComponent from "../ImportComponent/ImportComponent";




const Modals = ({
  Tittle,
  Label1,
  Label2,
  Label3,
  Label4,
  Label5, 
  Label99,
  Label51,  
  Label6,
  items,
  Label7,
  Label71,
  Label8,  
  Label9,
  Label31,
  Submit,
  Cancel,
  Show,
  Close,
  Field3,
  Field4,
  Byline,
  BylineMessage,
  Upload,
  Plan,
  OnChange,
  OnChange1,
  OnChange2,
  OnChange3,
  OnChange4,
  OnChange5,
  OnChange99,
  OnChange51,
  OnChange6,
  OnChange7,
  RadioOnChange,
  OnChange71,
  OnChange8,
  OnChange9,
  OnChange31,
  SOnChange1,
  S1Options,
  SOnChange2,
  SOnChange3,
  Value1,
  Value2,
  Value3,
  Value4,
  Value5,
  Value51,
  Value6,
  Value7,
  Value71,
  Value8,
  Value9,
  Value99,
  Value31,
  Value10, 

  Name1,
  Name2,
  Name3,
  Name4,
  Name5,
  RadioName,
  Name99,
  Name51,
  Name6,
  Name7,
  Name71,
  Name8,
  Name9,
  Name31,

  currentImg,
  UploadImage,
  UploadImageName,

  MinimumStartDate,
  MinimumEndDate,

  InputType1,
  InputType2,
  InputType3,
  InputType4,
  RadioOptions,
  InputType5,
  InputType99,
  InputType51,
  InputType6,
  InputType7,
  InputType71,
  InputType8,
  InputType9,
  InputType31,

  SLabel1,
  SLabel2,
  SLabel3,
  S1L1,
  S1V1,
  S1L2,
  RadioLabel,
  S1V2,
  S2L1,
  S2V1,
  S2L2,
  S2V2,
  S2L3,
  S2V3,
  S2L4,
  S2V4,
  S2L5,
  S2V5,
  S2L6,
  S2V6, 
  S2L11,
  S2V11,
  S2L12,
  S2V12,  
  S2L14,
  S2V14, 
   S2L15,
  S2V15,
  S2L13,
  S2V13, 
  S2L32,
  S2V32,
  S2L35,
  S2V35,
  S3V1,
  S3V2,
  S3L1,
  S3L2,
  S1N,
  S1V,
  S2N,
  S3N,
  profileImage,
  image,
  S2V, 
  S3V, 

  privateBadgesList,
  UploadOnChange,
  imageNameState, 

  UploadImageValue,
  PlanLabel1,
  PlanLabel2,
  PlanLabel3,
  SubmitForm,
  ShowImage,
  ShowCurrentImage,  

}) => { 

  const inputs = (Label, InputType, Name, Value, OnChange, classs, min ) => (
    <FloatingLabel
      controlId="floatingPassword"
      label={Label}
      className={`${classs} mb-3`}
    >
      <Form.Control
        placeholder={Label}
        type={InputType}
        name={Name}
        min={min}
        value={Value}
        onChange={OnChange}
      />
    </FloatingLabel>
  );

  const selects = (
    label,
    S1Name,
    SValue,
    OnChange,
    O1,
    O1V1,
    O2,
    O2V2,
    O3,
    O3V3,
    O4,
    O4V4,
    O5,
    O5V5,
    O6,
    O6V6,
    O11, 
    O11V11,
    O12,
    O12V12,
    O13,
    O13V13,
    O14,
    O14V14,
    O15,
    O15V15    
  ) => (
    <FloatingLabel controlId="floatingSelect" label={label}>
      <Form.Select
        aria-label="Floating label select example"
        name={S1Name}
        value={SValue}
        data-value={SValue}
        onChange={OnChange}
      >
        <option hidden>Select Options</option>
        {O1 && <option value={O1V1}>{O1}</option>}
        {O2 && <option value={O2V2}>{O2}</option>}
        {O3 && <option value={O3V3}>{O3}</option>}
        {O4 && <option value={O4V4}>{O4}</option>}
        {O5 && <option value={O5V5}>{O5}</option>}
        {O6 && <option value={O6V6}>{O6}</option>}
        {O11 && <option value={O11V11}>{O11}</option>}
        {O12 && <option value={O12V12}>{O12}</option>} 
        {O13 && <option value={O13V13}>{O13}</option>}
        {O14 && <option value={O14V14}>{O14}</option>} 
        {O15 && <option value={O15V15}>{O15}</option>} 
        {privateBadgesList && privateBadgesList.map((badge) => (
          <option key={badge.id} value={badge.id}>
            {badge.BadgeName}
          </option>
        ))}
      </Form.Select>
    </FloatingLabel>
  );

 const radioInputs = (Value10, RadioName, RadioLabel, RadioOptions, RadioOnChange) => (
    <Form.Group className="mb-3">
      <Form.Label>{RadioLabel}</Form.Label>
      {RadioOptions.map((option, index) => (
        <Form.Check
          key={index}
          type="radio"
          name={RadioName}
          label={option.label}
          value={option.value}
          checked={Value10 === option.value} 
          onChange={RadioOnChange}
        />
      ))}
    </Form.Group>
  );
 
  return (
    <>
      <Modal show={Show} onHide={Close} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <div className={tabbingModule.modal_heading}>
            <Modal.Title>{Tittle}</Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className={tabbingModule.modal_body}>
            <div className="d-flex">
              {Label1 &&
                inputs(Label1, InputType1, Name1, Value1, OnChange1, "w-50")}
              {Label2 &&
                inputs(
                  Label2,
                  InputType2,
                  Name2,
                  Value2,
                  OnChange2,
                  "w-50 ms-2"
                )}
            </div>

            {Plan && (
              <div className={`${tabbingModule.plan_gap} d-flex`}>
                {inputs("Data", "number", Name3, Value3, OnChange)}
                {inputs("Price", "number", Name4, Value4, OnChange)}
                {inputs("Validity", "number", Name5, Value5, OnChange)}
              </div>
            )}

            {Label3 && inputs(Label3, InputType3, Name3, Value3, OnChange3)}

 

            {SLabel1 &&
              selects(SLabel1, S1N, S1V, SOnChange1, S1L1, S1V1, S1L2, S1V2)}

          

            
            <div className="mb-3 mt-3">
              {SLabel2 &&
              selects(
                SLabel2,
                S2N,
               
                S2V, 
                
                SOnChange2,
                S2L1,
                S2V1,
                S2L2,
                S2V2, 
                S2L3,
                S2V3,
                S2L4, 
                S2V4,
                S2L5,
                S2V5, 
                S2L6,
                S2V6, 
                S2L11,
                S2V11,
                S2L12,
                S2V12,
                S2L13,
                S2V13, 
                S2L14,
                S2V14,
                S2L15,
                S2V15
              )}
            </div>  
            <div className="mb-3">
              {SLabel3 &&
              selects(SLabel3, S3N, S3V, SOnChange3, S3L1, S3V1, S3L2, S3V2, )}
             </div> 
           


            {((Tittle === "Add Competition" || Tittle === "Update Competition") && S2V === "Top") && (
              <>
                {Label5 && inputs("Rank 1", InputType5, Name5, Value5, OnChange5)}
                {Label8 && inputs(Label8, InputType8, Name8, Value8, OnChange8)}
                {Label9 && inputs(Label9, InputType9, Name9, Value9, OnChange9)}
              </>
            )}
            {((Tittle === "Add Competition" || Tittle === "Update Competition") && S2V === "Minimum") && (
              <>
                {Label4 && inputs(Label4, InputType4, Name4, Value4, OnChange4)}
                {Label5 && inputs(Label5, InputType5, Name5, Value5, OnChange5)}
              </>
            )}  
            {(Tittle != "Add Competition" && Tittle != "Update Competition") && (
              <>
                {Label4 && inputs(Label4, InputType4, Name4, Value4, OnChange4)}
                {Label5 && inputs(Label5, InputType5, Name5, Value5, OnChange5)}
              </>
            )} 
          
           {((Tittle === "Create Notification" || Tittle === "Update Notification") && (S2V === "scheduled" || S2V === "draft")) && (
              <>
                  {Label71 && inputs(Label71, InputType71, Name71, Value71, OnChange71, "w-501", MinimumEndDate)}
              </>
          )}

            {((Tittle === "Add New Badge" || Tittle === "Update Badges") && S2V === "Private") ? null : (
              <>
                {Label51 && inputs( Label51, InputType51, Name51, Value51, OnChange51)} 
              </>
            )}

              <div className="d-flex z_9 cust_sec_plan_popup">
              {Label6 &&
                inputs(
                  Label6,
                  InputType6,
                  Name6,
                  Value6,
                  OnChange6,
                  "w-501",
                  MinimumStartDate
                )}

              {Label7 &&
                inputs(
                  Label7,
                  InputType7,
                  Name7,
                  Value7, 
                  OnChange7,
                  "w-501 ms-2",
                  MinimumEndDate
                )}
            </div> 

           
          

           {(Tittle === "Update Phone Number")   && (
              <>
                <span>Phone Number</span>
              </>
            )}  

           <div>
          
            {Label99 && ( 
            <FloatingLabel
              controlId="floatingPassword"
              label={Label99}
              className="mb-3 custPasswordModal"
            >
              <Form.Control
                placeholder={Label99}
                type={InputType99}
                name={Name99}
                value={Value99}
                onChange={OnChange99}
                maxLength={8} 
              />
            </FloatingLabel>
          )}
          </div>
            
        <div className="mb-3 px-3 custradio">
            {RadioLabel &&
              radioInputs(
                Value10,
                RadioName,
                RadioLabel,
                RadioOptions,
                RadioOnChange
              )}
          </div>

       

          <div className="cust_pop_design">
  
            <div className="div1">
              {profileImage && (
                <span className="d-flex justify-content-center mt-2 cust_fff">
                  <img src={config.BASE_URL + profileImage} alt="PreviewImage" className="cust_img"/> 
                </span> 
              )} 
            </div>

            <div className="div1">
            {image && (
              <span className="d-flex justify-content-center mt-2 cust_fff">
                <img src={config.BASE_URL + image} alt="PreviewImagex" className="cust_img"/> 
              </span> 
            )} 
            </div>

            <div className="div2">
            {ShowImage && (
              <span className="d-flex justify-content-center mt-2 cust_fff">
                <img
                  src={ShowImage}
                  alt="PreviewImage"
                  className="cust_img"
                />
              </span> 
            )}

            </div>
             <div className="div3">

            {Upload && (
              <ImportComponent 
                imageNameState={imageNameState} 
                UploadOnChange={UploadOnChange}
              />
            )} 

            </div>

</div>
         
            
          </div>

          {/* {Byline && (
              <button className={`${tabbingModule.footerButton}  float-end`}>
                {BylineMessage}
              </button>
          )} */}
        </Modal.Body>

        <Modal.Footer>
          <div className={tabbingModule.modal_footer}>
            <Button variant="secondary" onClick={Close}>
              {Cancel}
            </Button>

            <Button variant="primary" onClick={SubmitForm}>
              {Submit}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Modals;

export const PromoteModal = ({
  Tittle,
  Label1,
  Label2,
  Label3,
  Label4,
  Submit,
  Cancel,
  Show,
  Close,
  Field4,
  BylineMessage,
}) => {
  return (
    <>
      <Modal show={Show} onHide={Close} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <div className={tabbingModule.modal_heading}>
            <Modal.Title>{Tittle}</Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className={tabbingModule.modal_body}>
            <Form.Select
              aria-label="Default select example"
              className="promoteSelect"
            >
              <option>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option> 
            </Form.Select>

            <div className="d-flex">
              <div className="w-50">
                <FloatingLabel
                  controlId="floatingPassword"
                  label={Label1}
                  className="mb-3"
                >
                  <Form.Control type="text" placeholder={Label2} />
                </FloatingLabel>
              </div>

              <div className="w-50">
                <FloatingLabel
                  controlId="floatingPassword"
                  label={Label2}
                  className="mb-3"
                >
                  <Form.Control type="text" placeholder={Label2} />
                </FloatingLabel>
              </div>
            </div>

            <FloatingLabel
              controlId="floatingPassword"
              label={Label3}
              className="mb-3"
            >
              <Form.Control type="text" placeholder={Label2} />
            </FloatingLabel>

            <div className={`${tabbingModule.promoteLastDiv} d-flex `}>
              <div className="w-50">
                {/* <FloatingLabel> */}

                <Form.Select
                  aria-label="Default select example"
                  className="promoteSelect"
                >
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>

              <div className="w-50">
                 {/*<PromotionalOfferInput Label3={Label3} /> */}
                <FloatingLabel
                  controlId="floatingPassword"
                  label={Label3}
                  className="mb-3"
                >
                  <Form.Control type="text" placeholder={Label3} />
                </FloatingLabel>
              </div>
            </div>
          </div>

          <button className={`${tabbingModule.footerButton}  float-end`}>
            {BylineMessage}
          </button>
        </Modal.Body>

        <Modal.Footer>
          <div className={tabbingModule.modal_footer}>
            <Button variant="secondary" onClick={Close}>
              {Cancel}
            </Button>

            <Button variant="primary">{Submit}</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const UserModal = ({
  Tittle,
  Field,
  Label2,
  Label3,
  Label4,
  Submit,
  Cancel,
  Show,
  Close,
  handleFetch,
  changeStatus,
}) => {
  return (
    <>
      <Modal show={Show} onHide={Close} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{Tittle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <FloatingLabel
              controlId="floatingInput"
              label="Reason For Decline"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Category"
                className="mb-3"
              />
            </FloatingLabel>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={Close}>
            Cancel
          </Button>

          <Button variant="primary" onClick={changeStatus}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ChangeStatusModal = ({
  Tittle,
  Message,
  Show,
  Close,
  Decline,
  ChangeStatus,
  OnChange,
  Field,
  CurrentValue,
  Name,
  Value,
}) => {
  return (
    <>
      <Modal show={Show} onHide={Close} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{Tittle}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          {Message && <h4>{Message}</h4>}

          {Decline && (
            <>
              <div>
                <FloatingLabel
                  controlId="floatingInput"
                  label={Field}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Category"
                    name={Name}
                    className="mb-3"
                    onChange={OnChange}
                    value={CurrentValue}
                  />
                </FloatingLabel>
              </div>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={Close}>
            Cancel
          </Button>

          <Button variant="primary " onClick={ChangeStatus}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
