import React from "react";
import { Table, Form, Row, Col } from "react-bootstrap";
import userTabbing from "../../../styles/userTabbing.module.scss";
import { ChangeStatusModal } from "../../../components/Modals/Modals";
import "../../../styles/pagination.scss";
import {
  // ApprovalButtonRank,
  ExportButtonRank,
  ImportButtonRank,
  MenuDropdown,
} from "../../../components/TableImportButton/button";
import Pagination from "../../../components/Pagination/Pagination";
import Searchbar from "../../../components/Searchbar/Searchbar";
import Loading from "../../../components/Loading/Loading";
import UsersTabbing from "../UsersTabbing";
import TableHead from "../../../components/TableHead/TableHead";
import OrganizationConstants from "./../../../Constants/OrganizationConstants/OrganizationConstants";
import UseUsers from "../../../Hooks/UseOrg";

const OrganisationsRequestsTable = () => {
  const {
    GETORGANIZATIONS_API_ENDPOINT,
    UPDATEUSERS_API_ENDPOINT,
    DELETE_API_ENDPOINT,
    EXPORT_API_ENDPOINT,
    IMPORT_API_ENDPOINT,
  } = OrganizationConstants();

  const {
    toggle1,
    setToggle1,
    rejectUsers,
    reasonForDecline,
    toggle,
    setToggle,
    handleFetch,
    approvedUser,
    deleteOrg,
    uuid,
    globalSearch,
    handleOnSelect,
    handleDownload,
    onFileChange,
    loader,
    items,
    handleShowDecline,
    navigate,
    handleShowApprove,
    pageCount,
    handlePageChange,
  } = UseUsers(GETORGANIZATIONS_API_ENDPOINT, UPDATEUSERS_API_ENDPOINT, DELETE_API_ENDPOINT);

  return (
    <>
      <ChangeStatusModal
        Tittle="Decline"
        Field="Reason For Decline"
        Decline="Decline"
        Show={toggle1}
        Close={setToggle1}
        handleFetch={handleFetch}
        ChangeStatus={rejectUsers}
        OnChange={reasonForDecline}
        Uuid={uuid}
      />

      <ChangeStatusModal
        Tittle="Approved"
        Message="You want to Approve this Organization ?"
        Show={toggle}
        Close={setToggle}
        handleFetch={handleFetch}
        ChangeStatus={approvedUser}
        Uuid={uuid}
      />

      <UsersTabbing OrganizationsMainTabPage />

      <div className={userTabbing.table_user}>
        <div>
          <Row>
            <Col xxl={4} xl={12} lg={12} md={12} sm={12}>
              <Searchbar
                FilterInput
                SPH="Search Name"
                OnChange={(e) => globalSearch(e)}
                FilterChange={(e) => handleOnSelect(e)}
                Value1=""
                Value2="approved"
                Value3="pending"
                Value4="rejected"
                Filter1="All"
                Filter2="Approved"
                Filter3="Pending"
                Filter4="Rejected"
              />
            </Col>

            <Col xxl={8} xl={12} lg={12} md={12} sm={12}>
              <div className={userTabbing.buttonupload}>
                {/* <ApprovalButtonRank showApproval={showApproval} /> */}

                <ExportButtonRank
                  ExportFile={() => handleDownload(EXPORT_API_ENDPOINT)}
                />

                <ImportButtonRank
                  SelectFiles={(e) => onFileChange(e, IMPORT_API_ENDPOINT)}
                />
              </div>
            </Col>
          </Row>
        </div>

        {loader ? (
          <Loading />
        ) : (
          <>
            <div className="table-responsive">
              <Table responsive="sm">
                <TableHead
                  CLabel="Organization Name"
                  One="Dealer Code"
                  Two="Size"
                  Three="Action"
                />

                <tbody>
                  {items ? (
                    items?.map(
                      (
                        {
                          id,
                          uuid,
                          name,
                          email,
                          dealerCode,
                          size,
                          dealerId,
                          user,
                          status,
                          comment,
                        },
                        index
                      ) => (
                        <tr key={id}>
                          <td>{name}</td>
                          <td className="text-center">{dealerCode}</td>
                          <td className="text-center">{size}</td>

                          <td className="text-center">
                            <div className={userTabbing.buttond}>
                              {(status === "pending" && (
                                <>
                                  <MenuDropdown
                                    Option1="Approve"
                                    Option2="Decline"
                                    Option3="Organisation Details"
                                    Option4="Delete"
                                    Uuid={uuid}
                                    Action1={handleShowApprove}
                                    Action2={handleShowDecline}
                                    Action3={() =>
                                      navigate(`/admin/organization/${uuid}`)
                                    }
                                    Action4={deleteOrg}
                                  />
                                </>
                              )) ||
                                (status === "approved" && (
                                  <>
                                    <MenuDropdown
                                      Option1="Decline"
                                      Option2="Organisation Details"
                                      Option3="Delete"
                                      Uuid={uuid}
                                      Action1={handleShowDecline}
                                      Action2={() =>
                                        navigate(`/admin/organization/${uuid}`)
                                      }
                                      Action3={deleteOrg}
                                    />
                                  </>
                                )) ||
                                (status === "rejected" && (
                                  <>
                                    <MenuDropdown
                                      Option1="Approve"
                                      Option2="Delete"
                                      Option3="Organisation Details"
                                      Uuid={uuid}
                                      Action1={handleShowApprove}
                                      Action3={() =>
                                        navigate(`/admin/organization/${uuid}`)
                                      }
                                      Action2={deleteOrg}
                                    />
                                  </>
                                ))}
                            </div>
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <h1 className="text-center">API ERROR...</h1>
                  )}
                </tbody>
              </Table>
            </div>
          </>
        )}

        <Pagination PageCount={pageCount} ChangePage={handlePageChange} />
      </div>
    </>
  );
};

export default OrganisationsRequestsTable;
